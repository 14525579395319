import { Select } from 'antd';
import React, { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import TagService from '../../service/tags';
import { selectOptionValues } from '../../redux/selectOptions/actions';

const { Option } = Select;

const tagService = new TagService();

const TagSearch = ({ handleSearchTags, searchTags }) => {
  const dispatch = useDispatch();
  const globalStates = useSelector(state => state?.Dashboard);
  const selectOptions = useSelector(state => state?.selectOptions);

  const { data: tags } = useQuery({
    queryKey: ['tags', globalStates?.companyId, selectOptions?.tags?.join('')],
    enabled: !!globalStates?.companyId,
    queryFn: () => tagService.getTagsByCompany(globalStates?.companyId),
    select: response => response.data.data,
  });

  useEffect(() => {
    if (tags?.length) {
      dispatch(selectOptionValues(tags, 'tags'));
    }
  }, [dispatch, tags]);

  return (
    <Select
      style={{
        minWidth: '250px',
        width: 'fit-content',
        maxWidth: '700px',
        height: 'auto',
        minHeight: '50px',
        marginBottom: '0.5rem',
      }}
      value={searchTags}
      allowClear
      size="large"
      mode="multiple"
      onChange={handleSearchTags}
      optionLabelProp="label"
      filterOption={(input, option) => option?.label?.toLowerCase().includes(input?.toLowerCase())}
      placeholder={
        <>
          <SearchOutlined style={{ marginRight: '5px' }} />
          <span>Search by tag(s)</span>
        </>
      }
    >
      {tags?.map(option => {
        const optionLabel = option.name;
        return (
          <Option value={option.id} label={optionLabel}>
            {optionLabel}
          </Option>
        );
      })}
    </Select>
  );
};

export default TagSearch;
