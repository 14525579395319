import axios from 'axios';
import { API_URL } from '../utility/constants';
import LoginService from './login';

const loginService = new LoginService();

export default class ChannelsService {
  getChannelsBySite(siteId) {
    const token = loginService.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/channels/by-site/${siteId}`,
    };
    return axios.get(option.url, config);
  }

  async forceMeasurement(channelId, companyId, start = true) {
    const res = await axios.post(
      `${API_URL}/channels/force-measurement${companyId ? `?companyId=${companyId}` : ''}`,
      { channelId, start },
      {
        headers: {
          Authorization: `Bearer ${loginService.getToken()}`,
        },
      },
    );

    return res.data.data;
  }

  async forceInjection(channelId, companyId, start = true) {
    const res = await axios.post(
      `${API_URL}/channels/force-injection${companyId ? `?companyId=${companyId}` : ''}`,
      { channelId, start },
      {
        headers: {
          Authorization: `Bearer ${loginService.getToken()}`,
        },
      },
    );

    return res.data.data;
  }

  async getChannelSettingsForRoom(roomId, companyId) {
    const res = await axios.get(`${API_URL}/opticontrol/channel/by-room/${roomId}?companyId=${companyId}`, {
      headers: {
        Authorization: `Bearer ${loginService.getToken()}`,
      },
    });

    return res.data.data;
  }

  async updateChannelSettings(data) {
    const res = await axios.post(`${API_URL}/opticontrol/channel/settings`, data, {
      headers: {
        Authorization: `Bearer ${loginService.getToken()}`,
      },
    });
    return res.data.data;
  }

  async testFlow(channelId, companyId, start = true) {
    const res = await axios.post(
      `${API_URL}/channels/test-flow${companyId ? `?companyId=${companyId}` : ''}`,
      { channelId, start },
      {
        headers: {
          Authorization: `Bearer ${loginService.getToken()}`,
        },
      },
    );

    return res.data.data;
  }
}
