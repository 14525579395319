import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Col, Modal, Row, Space, Spin, Table, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation, useQuery } from '@tanstack/react-query';
import DashboardService from '../../service/dashboard';
import useDashboard from '../../hooks/useDashboard';
import { Main, TableWrapper } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { roomHandler } from '../../redux/rooms/actions';
import { HomeCardWithChart, getAllAlertsPerRoom, getHomeTableColumns, homeDashboardInnerTableColumns } from './utils';
import ConfigurationContainer from './configuration-container';
import { selectOptionValues } from '../../redux/selectOptions/actions';
import { removeItem } from '../../utility/localStorageControl';
import HomeDashboardHeader from './header';
import { homeKey, measurementModuleSection, optiControlKey, sections } from '../../utility/constants';
import { optifluxColors } from '../../layout/colors';
import TagSearch from '../../components/tag-search';

const defaultState = {
  liveReader: false,
  tableView: true,
  value: true,
  deleteModal: false,
  record: {},
};

const dashboardService = new DashboardService();
const sectionsWithAlerts = [sections.watchdog, sections.storageInsights, sections.DCA];

const MainDashboard = () => {
  const globalStates = useSelector(state => state?.Dashboard);
  const filterState = useSelector(state => state.filter);
  const states = useSelector(state => state?.userByCompany);
  const history = useHistory();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    ...filterState,
    license: 'home',
    company: globalStates?.companyId ? globalStates?.companyId : '',
    page: 1,
  });
  const [siteIds, setSiteIds] = useState('');
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [alertsInfo, setAlertsInfo] = useState(null);
  const [searchTags, setSearchTags] = useState([]);
  const { rooms, isLoading, invalidate: invalidateDashboardQuery } = useDashboard(filters, {}, { searchTags });
  const { data: existingContents } = useQuery({
    queryKey: ['containerContents', selectedContainer?.id],
    enabled: !!selectedContainer?.id,
    queryFn: () => dashboardService.getContainerContents(selectedContainer?.id),
    select: response => response.data.data,
  });

  useEffect(() => {
    setFilters(filter => ({
      ...filter,
      company: globalStates?.companyId,
    }));
  }, [globalStates.companyId]);

  const [state, setState] = useState(defaultState);

  const toggleDashboardView = () => {
    setState(st => ({
      ...st,
      tableView: !state.tableView,
    }));
  };

  // set sites for user
  useEffect(() => {
    let sitesls = '';
    if (states?.user?.length > 0) {
      sitesls = states.user.map(x => x.id);
    }
    setSiteIds(sitesls);
    setFilters(filter => ({
      ...filter,
      site: sitesls,
    }));
  }, [states]);

  useEffect(() => {
    setFilters({
      ...filterState,
      license: 'home',
      company: globalStates?.companyId || '',
    });
  }, [filterState]);

  const handleNextPage = (value, record) => {
    const baseUrl = '/admin';
    const dashboard = 'dashboard';
    const optiConPath = `${baseUrl}/${sections[optiControlKey].route}/${dashboard}`;
    const measModulePath = `${baseUrl}/${sections[homeKey]?.route}/${measurementModuleSection?.route}/${dashboard}?selectedRooms=${record?.id}`;
    const siPath = `${baseUrl}/${sections.storageInsights.route}/${dashboard}`;
    const dcaPath = `${baseUrl}/dca/${dashboard}`;
    const extraPath = `&selected=${value}`;
    switch (value) {
      case 'default':
        dispatch(roomHandler(record?.id));
        history.push(measModulePath, '1');
        break;
      case 'external_set_temp':
      case 'external_set_gas':
        dispatch(roomHandler(record?.id));
        history.push(`${measModulePath}${extraPath}`);
        break;
      case 'manual_set':
        dispatch(roomHandler(record?.id));
        history.push(optiConPath, '1');
        break;
      case 'si':
        history.push(siPath);
        break;
      case 'dca':
        history.push(dcaPath);
        break;
      default:
        history.push(measModulePath, '1');
    }
  };

  const resetSelectedContainer = useCallback(id => {
    setSelectedContainer(null);
    const containerKey = id || 'newContainer';
    removeItem(containerKey);
    removeItem(`roomContents${containerKey}`);
  }, []);

  const savingContainer = useMutation({
    mutationFn: container => dashboardService.saveContainer(container),
    onSuccess: async (_, variables) => {
      notification.success({
        message: 'Saved successfully!',
      });
      await invalidateDashboardQuery();
      resetSelectedContainer(variables?.id);
    },
    onError: () =>
      notification.error({
        message: 'Unable to save, try again later or contact us.',
      }),
  });

  const deleteContainer = useMutation({
    mutationFn: id => dashboardService.deleteContainer(id),
    onSuccess: async (_, variables) => {
      notification.success({
        message: 'Deleted successfully!',
      });
      await invalidateDashboardQuery();
      resetSelectedContainer(variables?.id);
    },
    onError: () =>
      notification.error({
        message: 'Unable to delete, try again later or contact us.',
      }),
  });

  const handleConfigureContainerSave = newRecord => {
    savingContainer.mutate(newRecord);
  };

  const handleDelete = id => {
    deleteContainer.mutate(id);
  };

  const handleConfigureContainerCancel = () => {
    setSelectedContainer(null);
    dispatch(selectOptionValues([], 'channels'));
  };

  const handleSearchTags = selectedTags => {
    setSearchTags(selectedTags || []);
  };

  return (
    <div>
      {isLoading ? (
        <Col xs={24}>
          <div className="spin">
            <Spin />
          </div>
        </Col>
      ) : (
        <Main style={{ marginTop: '3rem' }}>
          <TagSearch handleSearchTags={handleSearchTags} searchTags={searchTags} />
          <ConfigurationContainer
            containerToConfigure={selectedContainer}
            handleSave={handleConfigureContainerSave}
            handleCancel={handleConfigureContainerCancel}
            setContainerToConfigure={setSelectedContainer}
            savingContainer={savingContainer}
            handleDelete={handleDelete}
            existingContents={existingContents}
          />
          <Modal
            title={`Alerts for container/room ${alertsInfo?.name}`}
            onCancel={() => setAlertsInfo(null)}
            visible={!!alertsInfo}
            style={{ top: '5%' }}
            destroyOnClose
            footer={null}
          >
            <Space direction="vertical">
              {!!alertsInfo &&
                sectionsWithAlerts.map(section => {
                  const totalNumberOfAlerts = Object.values(alertsInfo.numberOfAlerts).reduce(
                    (acc, curr) => acc + curr,
                    0,
                  );
                  const noAlerts = totalNumberOfAlerts === 0;
                  return (
                    <div style={{ marginBottom: '1rem' }}>
                      <Badge
                        count={alertsInfo?.numberOfAlerts[section.label]}
                        size="small"
                        showZero
                        status={noAlerts ? 'success' : 'error'}
                      >
                        <a
                          href={`/admin/${section.route}/dashboard`}
                          style={{ fontSize: '1.1rem', color: optifluxColors.lightGreen }}
                        >
                          {section.label}
                        </a>
                      </Badge>
                    </div>
                  );
                })}
            </Space>
          </Modal>
          <div>
            <Cards headless>
              {/* should be replaced with main dashboard header (filters for all the relevant values in the dashboard; RQ measurements etc) */}
              <HomeDashboardHeader
                toggleDashboardView={toggleDashboardView}
                tableView={state.tableView}
                siteIds={siteIds}
                setSelectedContainer={setSelectedContainer}
              />
              {state.tableView && (
                <TableWrapper className="table-responsive mt-20">
                  {isLoading ? (
                    <Col xs={24}>
                      <div className="spin">
                        <Spin />
                      </div>
                    </Col>
                  ) : (
                    <>
                      <div className="watch-dog-table pdf-generate-hide">
                        <Table
                          pagination={false}
                          className="components-table-demo-nested"
                          dataSource={rooms.length ? rooms : []}
                          scroll={{ x: 1650, y: 600 }}
                          childrenColumnName="children"
                          showSorterTooltip={false}
                          rowClassName={record => {
                            const allAlerts = getAllAlertsPerRoom(record);
                            return Object.values(allAlerts).some(a => a > 0) ? 'background-red-rq-invalid' : '';
                          }}
                          expandable={{
                            expandedRowRender: record => (
                              <TableWrapper className="table-responsive">
                                <Table
                                  expandedRowClassName={() => {
                                    return 'abcd';
                                  }}
                                  columns={homeDashboardInnerTableColumns(handleNextPage)}
                                  dataSource={record.room_contents}
                                  pagination={false}
                                />
                              </TableWrapper>
                            ),
                            expandIcon: ({ expanded = true, onExpand, record }) =>
                              expanded ? (
                                <FeatherIcon
                                  className="cursor-pointer"
                                  icon="minus"
                                  size={16}
                                  onClick={e => onExpand(record, e)}
                                  style={{
                                    border: '1px solid #8080803d',
                                    borderRadius: '2px',
                                  }}
                                />
                              ) : (
                                <FeatherIcon
                                  className="cursor-pointer"
                                  icon="plus"
                                  size={16}
                                  onClick={e => onExpand(record, e)}
                                  style={{
                                    border: '1px solid #8080803d',
                                    borderRadius: '2px',
                                  }}
                                />
                              ),
                          }}
                          rowKey="id"
                          columns={getHomeTableColumns(state, handleNextPage, setSelectedContainer, setAlertsInfo)}
                        />
                      </div>
                    </>
                  )}
                </TableWrapper>
              )}
            </Cards>
          </div>
          {!state.tableView && (
            <Row gutter={16}>
              {isLoading ? (
                <Col xs={24}>
                  <div className="spin">
                    <Spin />
                  </div>
                </Col>
              ) : (
                <>
                  {rooms?.map((single, i) => (
                    <Col lg={6} md={12} sm={12} xs={24}>
                      <HomeCardWithChart
                        key={`cardRoom_${i}`}
                        data={single}
                        // handleLogsModal={handleLogsModal}
                        // handleUploadRoomDataModal={handleUploadRoomDataModal}
                        // handleNextPage={handleNextPage}
                        // handleAlertModalWatchdog={handleAlertModalWatchdog}
                        // handleCheckbox={handleCheckbox}
                      />
                    </Col>
                  ))}
                </>
              )}
            </Row>
          )}
        </Main>
      )}
    </div>
  );
};

export default MainDashboard;
